import Hamburger from "./Hamburger";
import { useState } from "react";
import NavList from "./NavList";
import { useMediaQuery } from "react-responsive";

function Nav({ pages }) {
  const [hamburgerOpen, setHamburgerOpen] = useState(true);
  const smallerScreen = useMediaQuery({ maxWidth: 1100 }, undefined, handleMediaQueryChange);
  function handleMediaQueryChange(matches) {
    if (!matches) {
      setHamburgerOpen(true);
    }
  }
  function handleHamburgerOpen(value = null) {
    if (value === null) {
      setHamburgerOpen((prevState) => !prevState);
      return;
    }
    setHamburgerOpen(value);
  }

  return (
    <nav>
      <Hamburger handleHamburgerOpen={handleHamburgerOpen} hamburgerOpen={hamburgerOpen} />
      <div className="links" style={{ display: hamburgerOpen && smallerScreen ? "none" : "block" }}>
        <NavList pages={pages} handleHamburgerOpen={handleHamburgerOpen} />
      </div>
    </nav>
  );
}

export default Nav;
