import { useOutletContext } from "react-router-dom";

import TextSection from "./TextSection";
import "../styling/DetailedCrew.css";
import BackLink from "./BackLink";

function DetailedCrew() {
  const data = useOutletContext();
  return (
    <>
      {data === undefined ? null : (
        <div className="crew-member-detailed">
          <BackLink />
          {data.img ? <img src={data.img} alt="" loading="lazy" /> : null}
          <TextSection header={data.name} paragraphs={[{ text: data.job }]} />
          <TextSection paragraphs={data.detailedInfo} />
        </div>
      )}
    </>
  );
}

export default DetailedCrew;
