import CrewMember from "./CrewMember";
import PageTitle from "./PageTitle";
import "../styling/OurCrewPage.css";
import { Outlet, useParams } from "react-router-dom";
import BackgroundImage from "./BackgroundImage";

const crew = [
  {
    name: "Patrick Szypowski",
    job: "Owner",
    detailedInfo: [
      {
        text: "Patrick Szypowski has been in the construction industry since the late 1990's. He has (20+ years) of project management and estimating experience.  Patrick has extensive experience with planning and project management of all sizes and complexity. He specializes in providing hoisting solutions for the  commercial and industrial sectors, specialty/engineered lifting, pre-cast concrete erection, bridge construction and specialty hauling industries. He personally has overseen some of western Canada's largest construction initiatives in the past two decades.",
      },
    ],
    img: "/images/crew-page/crew/patrick-szypowski-headshot.webp",
    profileLink: "patrick-szypowski",
  },
  {
    name: "Gregg Hall",
    job: "Project Manager",
    detailedInfo: [
      {
        text: "Gregg is an exceptionally well rounded, tech savvy and knowledgeable erection supervisor with decades of industry experience and over sixteen (16+ years) of supervising a vast multitude of pre-cast concrete projects across all of Western Canada. Not only is Gregg an accomplished supervisor, but he is also a qualified and licensed red seal crane operator, a CWB certified all position welder and a CWB welding supervisor.  Gregg fosters a strong sense of pride, dedication to quality and efficient professionalism in his tight knit erection crew that shows in all of their work.",
      },
    ],
    img: "/images/crew-page/crew/gregg-hall-headshot.webp",
    profileLink: "gregg-hall",
  },
  {
    name: "Bryan Bergeron",
    job: "Crane & Rigging Supervisor",
    detailedInfo: [
      {
        text: "Bryan is a highly knowledgeable Crane & Rigging Supervisor, with vast experience (20+ years) in specialty hoisting, rigging and heavy hauling. He has years of experience leading crews, performing complex and often spectacular projects across Western Canada. Additionally, Bryan is a highly skilled and brilliant red seal certified crane operator, operating all sizes, makes and models of cranes.  Bryan has a deep and profound wealth of knowledge on rigging, craning and the heavy construction industry. Bryan has been a mentor and even a formal instructor for apprentices at all levels in the trade as well.",
      },
    ],
    img: "/images/crew-page/crew/bryan-bergeron-headshot.webp",
    profileLink: "bryan-bergeron",
  },
  {
    name: "Mathew Wright",
    job: "Crane & Rigging Supervisor",
    img: "/images/crew-page/crew/mathew-wright-headshot.webp",
    detailedInfo: [
      {
        text: `Mathew is an expert in his field as a hoisting, crane and rigging supervisor for large scale, multi crane projects.  Mathew is an exceptionally knowledgeable red seal crane operator as well as a tremendous communicator, who excels in high stress/high demand situations.  Mathew is dedicated to superior customer service, with a great attitude and a strong "can do anything" attitude.  Mathew's extensive experience (20+ years) on many of Western Canada's largest industrial sites is obvious with his seemingly effortless ability to multitask, schedule, estimate and coordinate for multiple trades simultaneously.`,
      },
    ],
    profileLink: "mathew-wright",
  },
  {
    name: "Matthew Kozeriz",
    job: "Crane & Rigging Supervisor",
    img: "",
    profileLink: "matthew-kozeriz",
    detailedInfo: [
      {
        text: "Matthew Kozoriz is a highly valued member of Phoenix Cranes' leadership team. With an impressive tenure spanning over two decades. Matthew is a red seal crane operator. His expertise extends across a wide spectrum of critical construction disciplines, including hoisting, rigging, heavy hauling, bridge construction, and precast erection. Matthew's contributions have been instrumental in the successful execution of numerous specialized hoisting projects throughout Manitoba. During his time with us, Matthew has played a pivotal role in mentoring and shaping the skills of many apprentices, enriching our workforce, and upholding our commitment to fostering industry talent. As one of Phoenix Cranes' top supervisors, he continues to exemplify our commitment to delivering top-tier construction solutions to our clients in Manitoba and beyond.",
      },
    ],
  },
  {
    name: "Julien Petit",
    job: "Precast Erection Supervisor",
    img: "",
    profileLink: "julien-petit",
    detailedInfo: [
      {
        text: "Julien Petite is a highly accomplished red seal ironworker. As a key member of our team at Phoenix Cranes, Julien serves as one of our top precast supervisors, overseeing critical aspects of our projects. With a career spanning over 15 years, Julien has amassed a wealth of experience when it comes to precast installation. His expertise and meticulous attention to detail have consistently proven instrumental in achieving the highest standards of quality and safety on our construction sites. Julien plays a pivotal role in Phoenix Cranes ability to continue  deliver exceptional quality and service ",
      },
    ],
  },
];

function OurCrewPage() {
  let { crewMember } = useParams();

  return (
    <main>
      <PageTitle title="Our Crew" image="/images/crew-page/banner/phoenix-employee-photo.webp" />
      <section className="crew-section">
        {crew.some((member) => member.profileLink === crewMember) ? (
          <Outlet context={crew.find((member) => member.profileLink === crewMember)} />
        ) : (
          <div className="crew">
            {crew.map((crewMember, index) => (
              <CrewMember crewMember={crewMember} key={index} />
            ))}
          </div>
        )}
        <BackgroundImage link="/images/logos/silver-phoenix-background.png" />
      </section>
    </main>
  );
}

export default OurCrewPage;
