import { useOutletContext } from "react-router-dom";
import ImageCarousel from "./ImageCarousel";
import TextSection from "./TextSection";
import "../styling/Expertise.css";
import Button from "./Button";

function Expertise() {
  const data = useOutletContext();
  return (
    <>
      {data === undefined ? null : (
        <section className="expertise">
          <div className="wrapper">
            <TextSection header={data.name} paragraphs={data.paragraphs} />
            <Button text={data.moreInfo.text} link={data.moreInfo.link} />
          </div>
          <ImageCarousel images={data.images} timer="10000" arrows={true} />
        </section>
      )}
    </>
  );
}

export default Expertise;
