import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function NavList({ pages, handleHamburgerOpen = null }) {
  const [currentPage, setCurrentPage] = useState("/");
  const location = useLocation();
  useEffect(() => {
    setCurrentPage("/" + location.pathname.split("/")[1]);
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [currentPage]);

  return (
    <ul>
      {pages.map((page, index) => (
        <li className={currentPage === page.link ? "nav-link selected" : "nav-link"} key={index}>
          <Link
            to={page.link}
            onClick={() => {
              if (handleHamburgerOpen === null) {
                return;
              }
              handleHamburgerOpen(true);
            }}>
            {page.name}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default NavList;
