import { useEffect, useRef, useState } from "react";
import TextSection from "./TextSection";
import emailjs from "@emailjs/browser";
import "../styling/ContactForm.css";
import Button from "./Button";

function ContactForm() {
  const form = useRef();
  const [formSubmitted, setFormSubmitted] = useState(null);

  useEffect(() => {
    if (formSubmitted !== null) {
      setTimeout(() => {
        setFormSubmitted(null);
      }, 5000);
    }
  }, [formSubmitted]);

  function sendEmail(event) {
    event.preventDefault(); // prevents the page from reloading when you hit “Send”

    emailjs.sendForm("service_d5c642v", "template_ijir8tr", form.current, "VkBipdkpi_H_0BY8V").then(
      (result) => {
        setFormSubmitted("Successfully submitted!");
        event.target.reset();
        // show the user a success message
      },
      (error) => {
        setFormSubmitted("Failed To submit form.");
        // show the user an error
      }
    );
  }

  return (
    <div className="contact-form">
      <TextSection header={"Send Us A Message"} />
      {formSubmitted === null ? null : <TextSection header={formSubmitted} />}
      <form ref={form} onSubmit={(event) => sendEmail(event)}>
        <label>Name</label>
        <input type="text" name="from_name" required placeholder="Your name here..." />
        <label>Email</label>
        <input type="email" name="from_email" required placeholder="Your email here..." />
        <label>Message</label>
        <textarea name="message" required placeholder="Your message here..." />
        <Button text="Send" formButton={true} />
      </form>
    </div>
  );
}

export default ContactForm;
