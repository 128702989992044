import { Outlet, useNavigate, useParams } from "react-router-dom";
import PageTitle from "./PageTitle";

import SelectionMenu from "./SelectionMenu";
import "../styling/OurFleetPage.css";
import BackgroundImage from "./BackgroundImage";

const equipment = [
  {
    name: "Conventional Truck Cranes",
    value: "conventional-truck-cranes",
    items: [
      {
        name: "100 Ton Link-Belt HC218A",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "150 ft" },
          { specPrompt: "Jib Length", spec: "30 ft" },
          { specPrompt: "Max Capacity", spec: "100 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "11 ft (w), 35.5 ft (l), 11.8 ft (h)" },
          { specPrompt: "Counterweight", spec: "46,500 lbs" },
          { specPrompt: "Cable", spec: "7/8 inch" },
        ],
        image: "/images/fleet-page/conventional/100-ton-truck-mount-link-belt-hc218a.webp",
        chart: "/images/fleet-page/conventional/link-belt-hc218a-specs.pdf",
      },
      {
        name: "125 Ton Conventional American 7530 TM",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "220 ft" },
          { specPrompt: "Jib Length", spec: "80 ft" },
          { specPrompt: "Max Capacity", spec: "125 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "11 ft (w), 35.7 ft (l), 13 ft (h)" },
          { specPrompt: "Counterweight", spec: "46,500 lbs" }, //verify
          { specPrompt: "Cable", spec: "7/8 inch" }, //verify
        ],
        image: "/images/fleet-page/conventional/125-ton-conventional-american-7530-tm.webp",
        chart: "/images/fleet-page/conventional/american-7530-specs.pdf",
      },
    ],
  },
  {
    name: "Crawler Cranes",
    value: "crawler-cranes",
    items: [
      {
        name: "165 Ton Kobelco 165C",

        image: "/images/fleet-page/crawler/165-ton-conventional-p&h-165c.webp",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "240 ft" },
          { specPrompt: "Jib Length", spec: "100 ft" },
          { specPrompt: "Max Capacity", spec: "165 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "22 ft (w),  26.25 ft (l), 12.25 ft (h)" },
          { specPrompt: "Counterweight", spec: "116,000 lbs" },
          { specPrompt: "Cable", spec: "1 inch" },
        ],
        chart: "/images/fleet-page/crawler/165-ton-conventional-p&h-165c-specs.pdf",
      },
      {
        name: "220 Ton Manitowoc 14000 Series 2",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "173 ft" },
          { specPrompt: "Jib Length", spec: "60 ft" },
          { specPrompt: "Max Capacity", spec: "220 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "22.25 ft (w),  27.35 ft (l), 11.25 ft (h)" },
          { specPrompt: "Counterweight", spec: "226,000 lbs" },
          { specPrompt: "Cable", spec: "26 mm" },
        ],
        image: "/images/fleet-page/crawler/14000-manitowoc-220-ton-self-erecting-crawler-crane.webp",
        chart: "/images/fleet-page/crawler/manitowoc-14000-specs.pdf",
      },
      {
        name: "300 Ton Manitowoc 2250 Series 3",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "300 ft" },
          { specPrompt: "Jib Length", spec: "120 ft" },
          { specPrompt: "Max Capacity", spec: "300 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "27 ft (w),  30.75 ft (l), 12 ft (h)" },
          { specPrompt: "Counterweight", spec: "369,200 lbs" },
          { specPrompt: "Cable", spec: "1-1/8 inch" },
        ],
        image: "/images/fleet-page/crawler/300-ton-conventional-manitowoc-2250.webp",
        chart: "/images/fleet-page/crawler/manitowoc-2250-siii-specs.pdf",
      },
      {
        name: "300 Ton Liebherr LR 1280",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "296 ft" },
          { specPrompt: "Luffing Jib Length", spec: "272 ft" },
          { specPrompt: "Max Capacity", spec: "300 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "24.6 ft (w),  29.6 ft (l), 15.4 ft (h)" },
          { specPrompt: "Counterweight", spec: "267,900 lbs" },
          { specPrompt: "Cable", spec: "28 mm" },
        ],
        image: "/images/fleet-page/crawler/300-ton-conventional-liebherr-lr-1280.webp",
        chart: "/images/fleet-page/crawler/liebherr-lr-1280-specs.pdf",
      },
    ],
  },
  {
    name: "Hydraulic Truck Cranes",
    value: "hydraulic-truck-cranes",
    items: [
      {
        name: "60 Ton Terex T560-1",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "110 ft" },
          { specPrompt: "Jib Length", spec: "57 ft" },
          { specPrompt: "Max Capacity", spec: "60 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "8.4 ft (w),  43.9 ft (l), 11.9 ft (h)" },
          { specPrompt: "Counterweight", spec: "15,000 lbs" },
          { specPrompt: "Cable", spec: "3/4 inch" },
        ],
        image: "/images/fleet-page/hydraulic/60-ton-terex-t-560-1.webp",
        chart: "/images/fleet-page/hydraulic/terex-t560-1-specs.pdf",
      },
      {
        name: "75 Ton Terex T775",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "126 ft" },
          { specPrompt: "Jib Length", spec: "57 ft" },
          { specPrompt: "Max Capacity", spec: "75 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "8.4 ft (w),  48.3 ft (l), 11.1 ft (h)" },
          { specPrompt: "Counterweight", spec: "15,000 lbs" },
          { specPrompt: "Cable", spec: "3/4 inch" },
        ],
        image: "/images/fleet-page/hydraulic/75-ton-terex-t775.webp",
        chart: "/images/fleet-page/hydraulic/terex-t775-specs.pdf",
      },
      {
        name: "90 Ton Terex T790",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "138 ft" },
          { specPrompt: "Jib Length", spec: "57 ft" },
          { specPrompt: "Max Capacity", spec: "95 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "8.4 ft (w),  48.3 ft (l), 11.1 ft (h)" }, //verify
          { specPrompt: "Counterweight", spec: "17,000 lbs" },
          { specPrompt: "Cable", spec: "3/4 inch" },
        ],
        image: "/images/fleet-page/hydraulic/90-ton-truck-mounted-terex-t790.webp",
        chart: "/images/fleet-page/hydraulic/terex-t790-specs.pdf",
      },
    ],
  },
  {
    name: "All Terrain Cranes",
    value: "all-terrain-cranes",
    items: [
      {
        name: "170 Ton Terex-Demag AC140",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "196.9 ft" },
          { specPrompt: "Jib Length", spec: "108.3 ft" },
          { specPrompt: "Max Capacity", spec: "170 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "9.9 ft (w),  61.5 ft (l), 13.2 ft (h)" },
          { specPrompt: "Counterweight", spec: "86,000 lbs" },
          { specPrompt: "Cable", spec: "0.83 inch" },
        ],
        image: "/images/fleet-page/all-terrain/170-ton-all-terrain-terex-ac140.png",
        chart: "/images/fleet-page/all-terrain/terex-demag-ac140.pdf",
      },
    ],
  },
  {
    name: "Rough Terrain Cranes",
    value: "rough-terrain-cranes",
    items: [
      {
        name: "35 Ton Rough Terrain Terex RT 335-1",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "94 ft" },
          { specPrompt: "Jib Length", spec: "49 ft" },
          { specPrompt: "Max Capacity", spec: "35 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "10.1 ft (w),  23.9 ft (l), 11.4 ft (h)" },
          { specPrompt: "Counterweight", spec: "8,900 lbs" },
          { specPrompt: "Cable", spec: "5/8 inch" },
        ],
        image: "/images/fleet-page/rough-terrain/35-ton-rough-terrain-terex-rt-335-1.webp",
        chart: "/images/fleet-page/rough-terrain/terex-rt-335-1-specs.pdf",
      },
      {
        name: "65 Ton Rough Terrain Terex RT 665-1",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "111 ft" },
          { specPrompt: "Jib Length", spec: "49 ft" },
          { specPrompt: "Max Capacity", spec: "65 Imperial Tons"},
          { specPrompt: "Dimensions", spec: "11.25 ft (w),  27.1 ft (l), 12.5 ft (h)" },
          { specPrompt: "Counterweight", spec: "14,200 lbs" },
          { specPrompt: "Cable Diameter", spec: "3/4 inch" },
        ],
        image: "/images/fleet-page/rough-terrain/65-ton-rough-terrain-terex-rt665.webp",
        chart: "/images/fleet-page/rough-terrain/terex-rt-665-1.pdf",
      },
    ],
  },
  {
    name: "Carry Deck Cranes",
    value: "carry-deck-cranes",
    items: [
      {
        name: "15 Ton Carry Deck Terex CD115",
        paragraphs: [
          { specPrompt: "Boom Length", spec: "52 ft" },
          { specPrompt: "Jib Length", spec: "N/A" },
          { specPrompt: "Max Capacity", spec: "15 Imperial Tons" },
          { specPrompt: "Dimensions", spec: "8 ft (w),  18.9 ft (l), 7.95 ft (h)" },
          { specPrompt: "Counterweight", spec: "5,000 lbs" },
          { specPrompt: "Cable Diameter", spec: "1/2 inch" },
        ],
        image: "/images/fleet-page/carry-deck/15-ton-carry-deck-terex-cd115.webp",
        chart: "/images/fleet-page/carry-deck/terex-cd115-specs.pdf",
      },
    ],
  },
  {
    name: "Highway Tractors",
    value: "highway-tractors",
    items: [
      {
        name: "Kenworth T680 Sleeper Tractor",
        paragraphs: [],
        image: "/images/fleet-page/trucks/kenworth-t680.webp",
      },
      {
        name: "Kenworth T800 Sleeper Tractor",
        paragraphs: [],
        image: "/images/fleet-page/trucks/kenworth-t800-sleeper-truck-tractor.webp",
      },
      {
        name: "Kenworth T800 Sleeper Tractor",
        paragraphs: [],
        image: "/images/fleet-page/trucks/kenworth-t800-sleeper-truck-tractor-2.webp",
      },
    ],
  },
  {
    name: "Other",
    value: "other",
    items: [
      {
        name: "JCB Telescopic Zoom Boom",
        paragraphs: [],
        image: "/images/fleet-page/other/jcb-telescopic-zoom-boom.webp",
      },
    ],
  },
];

function OurFleetPage() {
  const navigate = useNavigate();
  let { category } = useParams();

  function onEquipmentChange(value) {
    if (category === value) {
      return;
    }
    navigate(`/our-fleet/${value}`);
  }
  return (
    <main>
      <PageTitle title="Our Fleet" image="/images/fleet-page/banner/90-ton-truck-mounted-terex-t790.webp" />
      <div className="fleet-page">
        <section className="fleet-selection">
          <SelectionMenu options={equipment} onOptionChange={onEquipmentChange} defaultOption={category} />
        </section>
        <Outlet context={equipment.find((exercise) => category === exercise.value)} />
        <BackgroundImage link="/images/logos/silver-phoenix-background.png" />
      </div>
    </main>
  );
}

export default OurFleetPage;
