import { useEffect, useState } from "react";
import "../styling/SelectionMenu.css";
import { useMediaQuery } from "react-responsive";

function MultiSelectionMenu({ options, onOptionChange, defaultText, resize = true }) {
  const [currentOptions, setCurrentOptions] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);

  const smallerScreen = useMediaQuery({ maxWidth: 1200 }, undefined);

  useEffect(() => {
    onOptionChange(currentOptions);
  }, [currentOptions, onOptionChange]);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (event.target.closest(".multi-selection-menu") == null) {
      setMenuOpen(false);
    }
  };

  function changeOption(value = null) {
    if (value === null || value === "" || typeof value !== "string") {
      return;
    }

    setCurrentOptions((prevState) => {
      if (prevState.some((item) => item === value)) {
        return prevState.filter((item) => item !== value);
      }
      return [...prevState, value];
    });
  }

  return resize ? (
    <div className={"selection-menu multi-selection-menu resize"}>
      <ul>
        <li className={"default selected"} onClick={() => setMenuOpen((prevState) => !prevState)}>
          <span>
            {defaultText}
            <svg
              onClick={() => changeOption(currentOptions)}
              style={{ transform: menuOpen ? "rotate(180deg)" : "", display: smallerScreen ? "block" : "none" }}
              aria-hidden="true"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth={1.5}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        </li>
        {options.map((option, index) => (
          <li
            key={index}
            style={{
              color: currentOptions.some((item) => item === option.name) ? "var(--secondary-color)" : "var(--lightest-color)",
              display: smallerScreen ? (menuOpen ? "block" : "none") : "block",
            }}
            onClick={() => changeOption(option.value)}>
            <span>
              {option.name}
              {currentOptions.some((item) => item === option.name) ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5Z"></path>
                </svg>
              )}
            </span>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <div className={"selection-menu multi-selection-menu no-resize "}>
      <ul>
        <li className={"default selected"} onClick={() => setMenuOpen((prevState) => !prevState)}>
          <span>
            {defaultText}
            <svg
              onClick={() => changeOption(currentOptions)}
              style={{ transform: menuOpen ? "rotate(180deg)" : "" }}
              aria-hidden="true"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth={1.5}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        </li>
        {options.map((option, index) => (
          <li
            key={index}
            style={{
              color: currentOptions.some((item) => item === option.name) ? "var(--secondary-color)" : "var(--lightest-color)",
              display: menuOpen ? "block" : "none",
            }}
            onClick={() => changeOption(option.value)}>
            <span>
              {option.name}
              {currentOptions.some((item) => item === option.name) ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5Z"></path>
                </svg>
              )}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MultiSelectionMenu;
