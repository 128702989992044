import { useOutletContext } from "react-router-dom";
import TextSection from "./TextSection";
import "../styling/Equipment.css";
import Button from "./Button";

function Equipment() {
  const data = useOutletContext();
  return (
    <>
      {data === undefined ? null : (
        <div className="equipment">
          <TextSection header={data.name} />
          <div className="items">
            {data.items.map((item, index) => (
              <div className="item" key={index}>
                <TextSection image={item.image} />
                <TextSection header={item.name} paragraphs={item.paragraphs} />
                <div className="equipment-specs">
                  {item.paragraphs.map((item, index) => (
                    <p key={index}>
                      <b>{item.specPrompt}: </b>
                      {item.spec}
                    </p>
                  ))}
                </div>

                {item.chart === null ? null : <Button text="Crane Specification Chart (pdf)" download={item.chart} />}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Equipment;
