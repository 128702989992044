import { Outlet, useNavigate, useParams } from "react-router-dom";
import PageTitle from "./PageTitle";
import SelectionMenu from "./SelectionMenu";
import "../styling/OurExpertisePage.css";
import TextSection from "./TextSection";
import Button from "./Button";
import BackgroundImage from "./BackgroundImage";

const exercises = [
  {
    value: "crane-rental",
    name: "Crane Rental",
    paragraphs: [
      /*
      {
        text: "- 24 hr service/356 days/year",
      },
      {
        text: "- Wide range of cranes with capacities of 15-300 tons",
      },
      {
        text: "- Daily, weekly, and monthly rental rates available",
      },
      {
        text: "- Diverse selection of assist equipment available including:",
      },
      {
        text: " - Telescopic manlifts to 125 ft",
      },
      {
        text: " - Scissor lifts to 32 ft",
      },
      {
        text: " - Telescopic and standard forklifts",
      },
      {
        text: "- Serving Manitoba, Saskatchewan, and North Western Ontario",
      },
      */
    ],

    images: [
      { link: "/images/expertise-page/crane-rental/crane-rental-1.webp" },
      { link: "/images/expertise-page/crane-rental/crane-rental-2.webp" },
      { link: "/images/expertise-page/crane-rental/crane-rental-3.webp" },
    ],
    moreInfo: { text: "Our Fleet", link: "/our-fleet" },
  },
  {
    value: "bridge-construction",
    name: "Bridge Construction",
    paragraphs: [
      {
        text: "Phoenix Cranes bridge erection crew has over 20 years of experience in erecting bridges across Canada. They have now brought their expertise to the Phoenix Family and continue to construct steel and precast bridges of varying sizes across Manitoba and Ontario. Phoenix is your all in one bridge erection specialists.  ",
      },
    ],

    images: [
      { link: "/images/expertise-page/bridge-construction/bridge-construction-1.webp" },
      { link: "/images/expertise-page/bridge-construction/bridge-construction-2.webp" },
      { link: "/images/expertise-page/bridge-construction/bridge-construction-3.webp" },
    ],
    moreInfo: { text: "Our Work", link: "/our-work?sort-by=title-a-z" },
  },
  {
    value: "heavy-hauling",
    name: "Heavy Hauling",
    paragraphs: [
      {
        text: "",
      },
    ],
    images: [
      { link: "/images/expertise-page/heavy-hauling/heavy-hauling-1.webp" },
      { link: "/images/expertise-page/heavy-hauling/heavy-hauling-2.webp" },
    ],
    moreInfo: { text: "Our Work", link: "/our-work?sort-by=title-a-z&tag=Heavy Haul Trucking" },
  },
  {
    value: "precast-concrete-erection",
    name: "Precast Concrete Erection",
    paragraph:
      "Phoenix Cranes is a leader in precast installation. Forming condominium complexes, and parking structures, to warehouses, flooring, roofing, and bridge girders. Phoenix cranes is one of very few companies in Manitoba that specialize in this type of erection. Precast is preferred by many as it is weather resistant, fire resistant, environmentally friendly, and quick to install.",
    images: [
      { link: "/images/expertise-page/precast-concrete-erecting/precast-concrete-erecting-1.webp" },
      { link: "/images/expertise-page/precast-concrete-erecting/precast-concrete-erecting-2.webp" },
      { link: "/images/expertise-page/precast-concrete-erecting/precast-concrete-erecting-3.webp" },
    ],
    moreInfo: { text: "Our Work", link: "/our-work?sort-by=title-a-z&tag=Precast Concrete Erection" },
  },
  {
    value: "engineered-specialty-hoisting",
    name: "Engineered / Specialty Hoisting",
    paragraph: [
      {
        text: "- Wide selection of lifting accessories available including:",
      },
      {
        text: " - Manbaskets",
      },
      {
        text: " - Spreader bars",
      },
      {
        text: " - Hoistable platforms",
      },
      {
        text: " - Pallet forks",
      },
      {
        text: " - Timber pads",
      },
      {
        text: " - Rig mats",
      },
    ],
    images: [
      { link: "/images/expertise-page/engineered-specialty-hoisting/engineered-specialty-hoisting-1.webp" },
      { link: "/images/expertise-page/engineered-specialty-hoisting/engineered-specialty-hoisting-2.webp" },
      { link: "/images/expertise-page/engineered-specialty-hoisting/engineered-specialty-hoisting-3.webp" },
    ],
    moreInfo: { text: "Our Work", link: "/our-work?sort-by=title-a-z&tag=Mechanical Hoisting" },
  },
];

function OurExpertisePage() {
  const navigate = useNavigate();
  let { category } = useParams();

  function onExpertiseChange(value) {
    if (category === value) {
      return;
    }
    navigate(`/our-expertise/${value}`);
  }

  return (
    <main>
      <PageTitle title="Our Expertise" image="/images/expertise-page/banner/expertise-title.webp" />
      <div className="expertise-page">
        <section className="expertise-selection">
          <SelectionMenu options={exercises} onOptionChange={onExpertiseChange} defaultOption={category} />
        </section>
        <Outlet context={exercises.find((exercise) => category === exercise.value)} />
        <div className="brochure">
          <TextSection header="Phoenix Crane's Brochure" />
          <Button text="Download Brochure (pdf)" download="/documents/brochure/Phoenix Crane_s.pdf" />
        </div>
        <BackgroundImage link="/images/logos/silver-phoenix-background.png" />
      </div>
    </main>
  );
}

export default OurExpertisePage;
