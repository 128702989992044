import { Link } from "react-router-dom";
import "../styling/Button.css";

function Button({ text, link = null, download = null, formButton = null }) {
  return (
    <div className="button">
      {link === null ? null : <Link to={link}>{text}</Link>}
      {download === null ? null : (
        <a href={download} download>
          {text}
        </a>
      )}
      {formButton === null ? null : (
        <button type="submit">
          <span>{text}</span>
        </button>
      )}
    </div>
  );
}

export default Button;
