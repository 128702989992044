import { useEffect, useState } from "react";
import "../styling/ImageCarousel.css";

function ImageCarousel({ images = null, timer = null, lowerArrows = false }) {
  const [currentImageShown, setCurrentImageShown] = useState(0);
  const [userHasControl, setUserHasControl] = useState(false);

  useEffect(() => {
    if (!userHasControl && timer !== null) {
      const intervalId = setInterval(() => {
        setCurrentImageShown((prev) => prev + 1);
      }, timer);
      return () => clearInterval(intervalId);
    }
  }, [images.length, timer, userHasControl]);

  function UserChangesImage(amount) {
    setUserHasControl(true);
    setCurrentImageShown((prev) => (prev === 0 ? images.length : prev + amount));
  }

  return (
    <div className="carousel">
      <div className="images">
        {images === null
          ? null
          : images.map((image, index) => (
              <div className={"carousel-image" + (index === currentImageShown % images.length ? " current" : "")} key={index}>
                <img src={image.link} alt="" loading="lazy" />
              </div>
            ))}
      </div>

      {images.length > 1 ? (
        <>
          <div className={lowerArrows ? "lower arrows" : "arrows"}>
            <div className="previous" onClick={() => UserChangesImage(-1)}>
              <svg
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                strokeWidth={1.5}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <div className="next" onClick={() => UserChangesImage(1)}>
              <svg
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                strokeWidth={1.5}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>

          <div className="indicators">
            {images === null
              ? null
              : images.map((image, index) => (
                  <div
                    className={"indicator" + (index === currentImageShown % images.length ? " current" : "")}
                    key={index}
                    onClick={() => {
                      setUserHasControl(true);
                      setCurrentImageShown(index);
                    }}></div>
                ))}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ImageCarousel;
