import "../styling/TextSection.css";

function TextSection({ header = null, paragraphs = null, image = null }) {
  return (
    <div className="text-section">
      {header === null ? null : <h3>{header}</h3>}
      {image === null ? null : (
        <div className="image-container">
          <img src={image} alt="" loading="lazy" />
        </div>
      )}
      {paragraphs === null ? null : paragraphs.map((paragraph, index) => <p key={index}>{paragraph.text}</p>)}
    </div>
  );
}

export default TextSection;
