import TextSection from "./TextSection";
import Button from "./Button";
import "../styling/CrewMember.css";

function CrewMember({ crewMember }) {
  return (
    <div className="crew-member">
      <img src={crewMember.img} alt="" loading="lazy" />
      <TextSection header={crewMember.name} paragraphs={[{ text: crewMember.job }]} />
      {crewMember.detailedInfo === undefined ? null : <Button text="Read More" link={"/our-crew/" + crewMember.profileLink} />}
    </div>
  );
}

export default CrewMember;
