import { Link } from "react-router-dom";
import "../styling/GriddedLinks.css";

function GriddedLinks({ gridItems }) {
  return (
    <div className="grid-links">
      {gridItems.map((item, index) => (
        <div className="grid-link" key={index}>
          <Link to={item.link}>
            <h4>{item.title}</h4>
            <img src={item.image} alt="" loading="lazy" />
          </Link>
        </div>
      ))}
    </div>
  );
}

export default GriddedLinks;
