import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageTitle from "./PageTitle";
import { useEffect, useState } from "react";

import SelectionMenu from "./SelectionMenu";
import MultiSelectionMenu from "./MultiSelectionMenu";
import "../styling/OurWorkPage.css";
import JobGridItem from "./JobGridItem";
import BackgroundImage from "./BackgroundImage";

const jobs = [
  {
    title: "Amber Gates",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2019 },
    tags: ["Crawler Crane", "Precast Erection", "Grouting", "CWB Welding"],
    mainImage: "/images/work-page/jobs/amber-gates/amber-gates-title-image.webp",
    value: "amber-gates",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/amber-gates/amber-gates-1.webp" },
        { link: "/images/work-page/jobs/amber-gates/amber-gates-2.webp" },
        { link: "/images/work-page/jobs/amber-gates/amber-gates-3.webp" },
        { link: "/images/work-page/jobs/amber-gates/amber-gates-4.webp" },
        { link: "/images/work-page/jobs/amber-gates/amber-gates-5.webp" },
        { link: "/images/work-page/jobs/amber-gates/amber-gates-6.webp" },
        { link: "/images/work-page/jobs/amber-gates/amber-gates-7.webp" },
        { link: "/images/work-page/jobs/amber-gates/amber-gates-8.webp" },
        { link: "/images/work-page/jobs/amber-gates/amber-gates-9.webp" },
        { link: "/images/work-page/jobs/amber-gates/amber-gates-10.webp" },
        { link: "/images/work-page/jobs/amber-gates/amber-gates-11.webp" },
        { link: "/images/work-page/jobs/amber-gates/amber-gates-12.webp" },
      ],
    },
  },
  {
    title: "Sage Creek Buildings 1-9",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2019 },
    tags: ["Hydraulic Crane", "Precast Erection", "Steel Erection", "Grouting", "CWB Welding", "Mechanical Hoisting"],
    mainImage: "/images/work-page/jobs/sage-creek/sage-creek-title-image.webp",
    value: "sage-creek",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/sage-creek/sage-creek-1.webp" },
        { link: "/images/work-page/jobs/sage-creek/sage-creek-2.webp" },
        { link: "/images/work-page/jobs/sage-creek/sage-creek-3.webp" },
        { link: "/images/work-page/jobs/sage-creek/sage-creek-4.webp" },
        { link: "/images/work-page/jobs/sage-creek/sage-creek-5.webp" },
        { link: "/images/work-page/jobs/sage-creek/sage-creek-6.webp" },
        { link: "/images/work-page/jobs/sage-creek/sage-creek-7.webp" },
        { link: "/images/work-page/jobs/sage-creek/sage-creek-8.webp" },
        { link: "/images/work-page/jobs/sage-creek/sage-creek-10.webp" },
        { link: "/images/work-page/jobs/sage-creek/sage-creek-11.webp" },
      ],
    },
  },

  {
    title: "Paterson Oat Milling",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2021 },
    tags: ["Crawler Crane", "Precast Erection", "Grouting", "CWB Welding", "Caulking", "Mechanical Hoisting", "Machinery Moving"],
    mainImage: "/images/work-page/jobs/paterson/paterson-title-image.webp",
    value: "paterson-oat-milling",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/paterson/paterson-1.webp" },
        { link: "/images/work-page/jobs/paterson/paterson-2.webp" },
        { link: "/images/work-page/jobs/paterson/paterson-3.webp" },
        { link: "/images/work-page/jobs/paterson/paterson-4.webp" },
      ],
    },
  },
  {
    title: "59 North & South Floodway Bridge",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2021 },
    tags: ["Crawler Crane", "Bridge Erection", "Heavy Haul Trucking", "Steel Erection"],
    mainImage: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-title-image.webp",
    value: "59-floodway-bridge",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-1.webp" },
        { link: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-2.webp" },
        { link: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-3.webp" },
        { link: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-4.webp" },
        { link: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-5.webp" },
        { link: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-6.webp" },
        { link: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-7.webp" },
        { link: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-8.webp" },
        { link: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-9.webp" },
        { link: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-10.webp" },
        { link: "/images/work-page/jobs/59-floodway-bridge/59-floodway-bridge-11.webp" },
      ],
    },
  },
  {
    title: "221 Stradbrook",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2019 },
    tags: ["Precast Erection", "CWB Welding", "Steel Erection", "Grouting"],
    mainImage: "/images/work-page/jobs/221-stradbrook/221-stradbrook-title-image.webp",
    value: "221-stradbrook",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/221-stradbrook/221-stradbrook-1.webp" },
        { link: "/images/work-page/jobs/221-stradbrook/221-stradbrook-2.webp" },
        { link: "/images/work-page/jobs/221-stradbrook/221-stradbrook-3.webp" },
        { link: "/images/work-page/jobs/221-stradbrook/221-stradbrook-4.webp" },
        { link: "/images/work-page/jobs/221-stradbrook/221-stradbrook-5.webp" },
        { link: "/images/work-page/jobs/221-stradbrook/221-stradbrook-6.webp" },
        { link: "/images/work-page/jobs/221-stradbrook/221-stradbrook-7.webp" },
        { link: "/images/work-page/jobs/221-stradbrook/221-stradbrook-8.webp" },
        { link: "/images/work-page/jobs/221-stradbrook/221-stradbrook-9.webp" },
        { link: "/images/work-page/jobs/221-stradbrook/221-stradbrook-10.webp" },
      ],
    },
  },
  {
    title: "185/186 Smith Street",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2021 },
    tags: [
      "Precast Erection",
      "Steel Erection",
      "CWB Welding",
      "Crawler Crane",
      "Conventional Truck Crane",
      "Caulking",
      "Grouting",
      "Mechanical Hoisting",
    ],
    mainImage: "/images/work-page/jobs/185-smith/185-smith-title-image.webp",
    value: "185-186-smith-street",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/185-smith/185-smith-1.webp" },
        { link: "/images/work-page/jobs/185-smith/185-smith-2.webp" },
        { link: "/images/work-page/jobs/185-smith/185-smith-3.webp" },
        { link: "/images/work-page/jobs/185-smith/185-smith-4.webp" },
        { link: "/images/work-page/jobs/185-smith/185-smith-5.webp" },
        { link: "/images/work-page/jobs/185-smith/185-smith-6.webp" },
        { link: "/images/work-page/jobs/185-smith/185-smith-7.webp" },
        { link: "/images/work-page/jobs/185-smith/185-smith-8.webp" },
        { link: "/images/work-page/jobs/185-smith/185-smith-9.webp" },
        { link: "/images/work-page/jobs/185-smith/185-smith-10.webp" },
        { link: "/images/work-page/jobs/185-smith/185-smith-11.webp" },
      ],
    },
  },
  {
    title: "Roquette Site",
    location: { city: "Portage la Prairie", province: "Manitoba" },
    date: { year: 2020 },
    tags: ["Crawler Crane", "Hydraulic Crane", "Steel Erection", "Mechanical Hoisting", "Crane Rental"],
    mainImage: "/images/work-page/jobs/roquette-site/roquette-site-title-image.webp",
    value: "roquette-site",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/roquette-site/roquette-site-1.webp" },
        { link: "/images/work-page/jobs/roquette-site/roquette-site-2.webp" },
        { link: "/images/work-page/jobs/roquette-site/roquette-site-3.webp" },
        { link: "/images/work-page/jobs/roquette-site/roquette-site-4.webp" },
      ],
    },
  },
  {
    title: "Assiniboine River Bridge",
    location: { city: "Portage la Prairie", province: "Manitoba" },
    date: { year: 2020 },
    tags: ["Heavy Haul Trucking", "Bridge Erection"],
    mainImage: "/images/work-page/jobs/assiniboine-bridge/assiniboine-bridge-title-image.webp",
    value: "assiniboine-river-bridge",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/assiniboine-bridge/assiniboine-bridge-1.webp" },
        { link: "/images/work-page/jobs/assiniboine-bridge/assiniboine-bridge-2.webp" },
        { link: "/images/work-page/jobs/assiniboine-bridge/assiniboine-bridge-3.webp" },
        { link: "/images/work-page/jobs/assiniboine-bridge/assiniboine-bridge-4.webp" },
        { link: "/images/work-page/jobs/assiniboine-bridge/assiniboine-bridge-5.webp" },
        { link: "/images/work-page/jobs/assiniboine-bridge/assiniboine-bridge-6.webp" },
        { link: "/images/work-page/jobs/assiniboine-bridge/assiniboine-bridge-7.webp" },
      ],
    },
  },
  {
    title: "Molson Memory Care",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2021 },
    tags: [
      "Crawler Crane",
      "Hydraulic Crane",
      "Steel Erection",
      "Precast Erection",
      "Grouting",
      "CWB Welding",
      "Crane Rental",
      "Mechanical Hoisting",
      "All Terrain Crane",
    ],
    mainImage: "/images/work-page/jobs/molson-memory/molson-memory-title-image.webp",
    value: "molson-memory-care",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/molson-memory/molson-memory-1.webp" },
        { link: "/images/work-page/jobs/molson-memory/molson-memory-2.webp" },
        { link: "/images/work-page/jobs/molson-memory/molson-memory-3.webp" },
        { link: "/images/work-page/jobs/molson-memory/molson-memory-4.webp" },
        { link: "/images/work-page/jobs/molson-memory/molson-memory-5.webp" },
        { link: "/images/work-page/jobs/molson-memory/molson-memory-6.webp" },
      ],
    },
  },
  {
    title: "Ronald McDonald House",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2021 },
    tags: ["Crawler Crane", "Precast Erection", "Crane Rental", "Grouting"],
    mainImage: "/images/work-page/jobs/ronald-mcdonald/ronald-mcdonald-title-image.webp",
    value: "ronald-mcdonald-house",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/ronald-mcdonald/ronald-mcdonald-1.webp" },
        { link: "/images/work-page/jobs/ronald-mcdonald/ronald-mcdonald-2.webp" },
        { link: "/images/work-page/jobs/ronald-mcdonald/ronald-mcdonald-3.webp" },
      ],
    },
  },
  {
    title: "Waverley West",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2022 },
    tags: ["All Terrain Crane", "Crane Rental", "Precast Erection", "Grouting"],
    mainImage: "/images/work-page/jobs/waverley-west/waverley-west-title-image.webp",
    value: "waverley-west",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/waverley-west/waverley-west-1.webp" },
        { link: "/images/work-page/jobs/waverley-west/waverley-west-2.webp" },
      ],
    },
  },
  {
    title: "281 River / Tudor Apartments",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2022 },
    tags: ["Conventional Truck Crane", "Crane Rental", "Precast Erection", "Grouting", "CWB Welding"],
    mainImage: "/images/work-page/jobs/tudor/tudor-title-image.webp",
    value: "tudor-apartments",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/tudor/tudor-1.webp" },
        { link: "/images/work-page/jobs/tudor/tudor-2.webp" },
        { link: "/images/work-page/jobs/tudor/tudor-3.webp" },
      ],
    },
  },
  {
    title: "Calm Air Hanger",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2021 },
    tags: ["Steel Erection", "Hydraulic Crane", "Crane Rental"],
    mainImage: "/images/work-page/jobs/calm-air/calm-air-title-image.webp",
    value: "calm-air-hanger",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/calm-air/calm-air-1.webp" },
        { link: "/images/work-page/jobs/calm-air/calm-air-2.webp" },
        { link: "/images/work-page/jobs/calm-air/calm-air-3.webp" },
        { link: "/images/work-page/jobs/calm-air/calm-air-4.webp" },
        { link: "/images/work-page/jobs/calm-air/calm-air-5.webp" },
        { link: "/images/work-page/jobs/calm-air/calm-air-6.webp" },
        { link: "/images/work-page/jobs/calm-air/calm-air-7.webp" },
      ],
    },
  },
  {
    title: "Haywood Expansion",
    location: { city: "Haywood", province: "Manitoba" },
    date: { year: 2022 },
    tags: ["Steel Erection", "Hydraulic Crane", "Crane Rental"],
    mainImage: "/images/work-page/jobs/haywood-expansion/haywood-expansion-title-image.webp",
    value: "haywood-expansion",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/haywood-expansion/haywood-expansion-1.webp" },
        { link: "/images/work-page/jobs/haywood-expansion/haywood-expansion-2.webp" },
        { link: "/images/work-page/jobs/haywood-expansion/haywood-expansion-3.webp" },
        { link: "/images/work-page/jobs/haywood-expansion/haywood-expansion-4.webp" },
        { link: "/images/work-page/jobs/haywood-expansion/haywood-expansion-5.webp" },
      ],
    },
  },
  {
    title: "Bishop Apartments",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2022 },
    tags: ["Crawler Crane", "Precast Erection", "CWB Welding", "Caulking", "Steel Erection", "Overhead Crane Installation", "Grouting"],
    mainImage: "/images/work-page/jobs/bishop/bishop-title-image.webp",
    value: "bishop-apartments",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/bishop/bishop-1.webp" },
        { link: "/images/work-page/jobs/bishop/bishop-2.webp" },
        { link: "/images/work-page/jobs/bishop/bishop-3.webp" },
        { link: "/images/work-page/jobs/bishop/bishop-4.webp" },
        { link: "/images/work-page/jobs/bishop/bishop-5.webp" },
        { link: "/images/work-page/jobs/bishop/bishop-6.webp" },
        { link: "/images/work-page/jobs/bishop/bishop-7.webp" },
        { link: "/images/work-page/jobs/bishop/bishop-8.webp" },
      ],
    },
  },
  {
    title: "Norway House Bridge",
    location: { city: "Northern", province: "Manitoba" },
    date: { year: 2022 },
    tags: ["All Terrain Crane", "Mechanical Hoisting", "Bridge Erection"],
    mainImage: "/images/work-page/jobs/norway-house/norway-house-title-image.webp",
    value: "norway-house",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/norway-house/norway-house-1.webp" },
        { link: "/images/work-page/jobs/norway-house/norway-house-2.webp" },
        { link: "/images/work-page/jobs/norway-house/norway-house-3.webp" },
        { link: "/images/work-page/jobs/norway-house/norway-house-4.webp" },
        { link: "/images/work-page/jobs/norway-house/norway-house-5.webp" },
        { link: "/images/work-page/jobs/norway-house/norway-house-6.webp" },
        { link: "/images/work-page/jobs/norway-house/norway-house-7.webp" },
        { link: "/images/work-page/jobs/norway-house/norway-house-8.webp" },
        { link: "/images/work-page/jobs/norway-house/norway-house-9.webp" },
      ],
    },
  },
  {
    title: "Daly Street Bridges",
    location: { city: "Brandon", province: "Manitoba" },
    date: { year: 2023 },
    tags: [
      "Crawler Crane",
      "Steel Erection",
      "CWB Welding",
      "Grouting",
      "Heavy Haul Trucking",
      "Precast Erection",
      "Crane Rental",
      "Lateral Post-Tensioning",
      "Fencing Installation",
      "Bridge Erection",
    ],
    mainImage: "/images/work-page/jobs/daly/daly-title-image.webp",
    value: "daly-street-bridges",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/daly/daly-1.webp" },
        { link: "/images/work-page/jobs/daly/daly-2.webp" },
        { link: "/images/work-page/jobs/daly/daly-3.webp" },
        { link: "/images/work-page/jobs/daly/daly-4.webp" },
        { link: "/images/work-page/jobs/daly/daly-5.webp" },
        { link: "/images/work-page/jobs/daly/daly-6.webp" },
        { link: "/images/work-page/jobs/daly/daly-7.webp" },
        { link: "/images/work-page/jobs/daly/daly-8.webp" },
        { link: "/images/work-page/jobs/daly/daly-9.webp" },
        { link: "/images/work-page/jobs/daly/daly-10.webp" },
        { link: "/images/work-page/jobs/daly/daly-11.webp" },
        { link: "/images/work-page/jobs/daly/daly-12.webp" },
        { link: "/images/work-page/jobs/daly/daly-13.webp" },
        { link: "/images/work-page/jobs/daly/daly-14.webp" },
        { link: "/images/work-page/jobs/daly/daly-15.webp" },
        { link: "/images/work-page/jobs/daly/daly-16.webp" },
        { link: "/images/work-page/jobs/daly/daly-17.webp" },
        { link: "/images/work-page/jobs/daly/daly-18.webp" },
        { link: "/images/work-page/jobs/daly/daly-19.webp" },
        { link: "/images/work-page/jobs/daly/daly-20.webp" },
        { link: "/images/work-page/jobs/daly/daly-21.webp" },
        { link: "/images/work-page/jobs/daly/daly-22.webp" },
        { link: "/images/work-page/jobs/daly/daly-23.webp" },
      ],
    },
  },
  {
    title: "Portage la Prairie Bypass",
    location: { city: "Portage la Prairie", province: "Manitoba" },
    date: { year: 2023 },
    tags: ["Crawler Crane", "Precast Erection", "Heavy Haul Trucking", "Lateral Post-Tensioning"],
    mainImage: "/images/work-page/jobs/portage-bypass/portage-bypass-title-image.webp",
    value: "portage-la-prairie-bypass",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/portage-bypass/portage-bypass-1.webp" },
        { link: "/images/work-page/jobs/portage-bypass/portage-bypass-2.webp" },
        { link: "/images/work-page/jobs/portage-bypass/portage-bypass-3.webp" },
        { link: "/images/work-page/jobs/portage-bypass/portage-bypass-4.webp" },
        { link: "/images/work-page/jobs/portage-bypass/portage-bypass-5.webp" },
        { link: "/images/work-page/jobs/portage-bypass/portage-bypass-6.webp" },
        { link: "/images/work-page/jobs/portage-bypass/portage-bypass-7.webp" },
        { link: "/images/work-page/jobs/portage-bypass/portage-bypass-8.webp" },
        { link: "/images/work-page/jobs/portage-bypass/portage-bypass-9.webp" },
        { link: "/images/work-page/jobs/portage-bypass/portage-bypass-10.webp" },
        { link: "/images/work-page/jobs/portage-bypass/portage-bypass-11.webp" },
      ],
    },
  },
  {
    title: "Drunken River Bridge",
    location: { city: "Silver Harbour", province: "Manitoba" },
    date: { year: 2023 },
    tags: ["Hydraulic Crane", "All Terrain Crane", "Hauling", "Steel Erection", "CWB Welding", "Precast Erection", "Bridge Erection"],
    mainImage: "/images/work-page/jobs/drunken-bridge/drunken-bridge-title-image.webp",
    value: "drunken-river-bridge",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/drunken-bridge/drunken-bridge-1.webp" },
        { link: "/images/work-page/jobs/drunken-bridge/drunken-bridge-2.webp" },
        { link: "/images/work-page/jobs/drunken-bridge/drunken-bridge-3.webp" },
        { link: "/images/work-page/jobs/drunken-bridge/drunken-bridge-4.webp" },
        { link: "/images/work-page/jobs/drunken-bridge/drunken-bridge-5.webp" },
        { link: "/images/work-page/jobs/drunken-bridge/drunken-bridge-6.webp" },
        { link: "/images/work-page/jobs/drunken-bridge/drunken-bridge-7.webp" },
      ],
    },
  },
  {
    title: "Paterson Grain Elevator",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2023 },
    tags: ["Crawler Crane", "Crane Rental", "Steel Erection"],
    mainImage: "/images/work-page/jobs/paterson-elevator/paterson-elevator-title-image.webp",
    value: "paterson-grain-elevator",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/paterson-elevator/paterson-elevator-1.webp" },
        { link: "/images/work-page/jobs/paterson-elevator/paterson-elevator-2.webp" },
        { link: "/images/work-page/jobs/paterson-elevator/paterson-elevator-3.webp" },
        { link: "/images/work-page/jobs/paterson-elevator/paterson-elevator-4.webp" },
      ],
    },
  },
  {
    title: "St. Mary's At Perimeter Highway",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2023 },
    tags: ["Crawler Crane", "Crane Rental", "Heavy Haul Trucking", "Precast Erection", "Grouting", "Lateral Post-Tensioning"],
    mainImage: "/images/work-page/jobs/st-marys-perimeter/st-marys-perimeter-title-image.webp",
    value: "st-marys-perimeter",
    details: {
      paragraphs: [],
      images: [{ link: "/images/work-page/jobs/st-marys-perimeter/st-marys-perimeter-1.webp" }],
    },
  },
  {
    title: "Diversity Gardens",
    location: { city: "Winnipeg", province: "Manitoba" },
    date: { year: 2021 },
    tags: ["All Terrain Crane", "Steel Erection", "Crane Rental"],
    mainImage: "/images/work-page/jobs/diversity-gardens/diversity-gardens-title-image.webp",
    value: "diversity-gardens",
    details: {
      paragraphs: [],
      images: [
        { link: "/images/work-page/jobs/diversity-gardens/diversity-gardens-1.webp" },
        { link: "/images/work-page/jobs/diversity-gardens/diversity-gardens-2.webp" },
        { link: "/images/work-page/jobs/diversity-gardens/diversity-gardens-3.webp" },
        { link: "/images/work-page/jobs/diversity-gardens/diversity-gardens-4.webp" },
        { link: "/images/work-page/jobs/diversity-gardens/diversity-gardens-5.webp" },
      ],
    },
  },
];

function OurWorkPage() {
  let { jobSite } = useParams();
  let [searchParams] = useSearchParams();

  let [filteredJobs, setFilteredJobs] = useState(jobs);
  let [selectedTags, setSelectedTags] = useState([]);
  let [selectedSortBy, setSelectedSortBy] = useState(searchParams.get("sort-by"));
  let [tags, setTags] = useState(
    jobs
      .map((job) => job.tags)
      .flat(1)
      .filter((value, index, array) => array.indexOf(value) === index)
      .sort((a, b) => {
        if (
          searchParams.getAll("tag").some((item) => {
            return item === a;
          })
        ) {
          return -1;
        }
        return a.localeCompare(b);
      })
      .map((tag) => {
        return { name: tag, value: tag };
      })
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (jobSite === undefined) {
      let tagsString = "";
      selectedTags.forEach((tag) => (tagsString += `&tag=${tag}`));
      navigate(`/our-work?sort-by=${selectedSortBy ?? "title-a-z"}${tagsString}`);
    }
  }, [navigate, jobSite, searchParams, selectedTags, selectedSortBy]);

  useEffect(() => {
    if (searchParams.has("tag")) {
      setFilteredJobs(
        jobs.filter((job) => {
          return searchParams.getAll("tag").every((tag) => {
            return job.tags.some((jobTag) => jobTag === tag);
          });
        })
      );
    } else {
      setFilteredJobs(jobs);
    }
  }, [searchParams]);

  useEffect(() => {
    switch (searchParams.get("sort-by")) {
      case "title-a-z":
        setFilteredJobs((prevState) => prevState.sort((a, b) => a.title.localeCompare(b.title)));
        break;
      case "title-z-a":
        setFilteredJobs((prevState) => prevState.sort((a, b) => b.title.localeCompare(a.title)));
        break;
      case "date-new":
        setFilteredJobs((prevState) =>
          prevState.sort((a, b) => {
            if (a.date.year < b.date.year) {
              return 1;
            }
            if (a.date.year > b.date.year) {
              return -1;
            }
            return 0;
          })
        );
        break;
      case "date-old":
        setFilteredJobs((prevState) =>
          prevState.sort((a, b) => {
            if (a.date.year < b.date.year) {
              return -1;
            }
            if (a.date.year > b.date.year) {
              return 1;
            }
            return 0;
          })
        );
        break;
      default:
        setFilteredJobs((prevState) => prevState.sort((a, b) => b.title.localeCompare(a.title)));
    }
  }, [searchParams]);

  useEffect(() => {
    setTags(
      filteredJobs
        .map((job) => job.tags)
        .flat(1)
        .filter((value, index, array) => array.indexOf(value) === index)
        .sort((a, b) => {
          if (
            searchParams.getAll("tags").some((item) => {
              return item === a;
            })
          ) {
            return -1;
          } else if (
            searchParams.getAll("tags").some((item) => {
              return item === b;
            })
          ) {
            return 1;
          } else {
            return a.localeCompare(b);
          }
        })
        .map((tag) => {
          return { name: tag, value: tag };
        })
    );
  }, [searchParams, filteredJobs]);

  function onSortByChange(option) {
    setSelectedSortBy(option);
  }
  function onTagChange(options) {
    setSelectedTags(options);
  }

  return (
    <main>
      <PageTitle title="Our Work" image="/images/work-page/banner/our-work-banner.webp" />
      <div className="work-page">
        <BackgroundImage link="/images/logos/silver-phoenix-background.png" />
        {jobs.some((job) => job.value === jobSite) ? (
          <Outlet context={jobs.find((job) => job.value === jobSite)} />
        ) : (
          <>
            <section className="sorting-work">
              <SelectionMenu
                options={[
                  { name: "Title (A-Z)", value: "title-a-z" },
                  { name: "Title (Z-A)", value: "title-z-a" },
                  { name: "Date (New-Old)", value: "date-new" },
                  { name: "Date (Old-New)", value: "date-old" },
                ]}
                onOptionChange={onSortByChange}
                defaultOption={selectedSortBy ?? "title-a-z"}
                resize={false}
              />
              <MultiSelectionMenu options={tags} onOptionChange={onTagChange} defaultText={"Tags"} resize={false} />
            </section>
            <section className="job-list">
              {filteredJobs.length <= 0 ? (
                <p>No Results. Try loosening your search tags.</p>
              ) : (
                filteredJobs.map((job, index) => <JobGridItem job={job} key={index} />)
              )}
            </section>
          </>
        )}
      </div>
    </main>
  );
}

export default OurWorkPage;
