function BackgroundImage({ link }) {
  return (
    <div
      className="background-image"
      style={{
        opacity: "0.3",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "-1",
        backgroundImage: "url(" + link + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}></div>
  );
}

export default BackgroundImage;
