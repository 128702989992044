import "../styling/Footer.css";
import NavList from "./NavList";
import SocialList from "./SocialList";

const socials = [
  {
    name: "YouTube",
    socialLink: "https://www.youtube.com/channel/UC14G2c3UblukWEOMMw3Nc6Q",
  },
  {
    name: "Facebook",
    socialLink: "https://www.facebook.com/PhoenixCraneWPG/",
  },
  {
    name: "Instagram",
    socialLink: "https://www.instagram.com/phoenixcrane_wpg/",
  },
  {
    name: "LinkedIn",
    socialLink: "https://www.linkedin.com/company/phoenix-crane-erectors-company-ltd",
  },
];

function Footer({ pages }) {
  return (
    <footer>
      <SocialList socials={socials} />
      <nav>
        <NavList pages={pages} />
      </nav>
      <p className="copyright">The content of this website is proprietary in nature - All rights reserved.</p>
    </footer>
  );
}

export default Footer;
