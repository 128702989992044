import TextSection from "./TextSection";
import "../styling/JobGridItem.css";
import { Link } from "react-router-dom";

function JobGridItem({ job }) {
  return (
    <div className="job">
      <Link to={`/our-work/${job.value}`}>
        <img src={job.mainImage} alt="" loading="lazy" />
        <div className="job-information">
          <TextSection header={job.title} paragraphs={[{ text: `${job.location.city}, ${job.location.province}` }]} />
        </div>
      </Link>
    </div>
  );
}

export default JobGridItem;
