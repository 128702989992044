import { useOutletContext } from "react-router-dom";
import TextSection from "./TextSection";
import ImageCarousel from "./ImageCarousel";
import "../styling/JobSite.css";
import BackLink from "./BackLink";

function JobSite() {
  const data = useOutletContext();

  return (
    <div className="job-site">
      {data === undefined ? null : (
        <>
          <div className="job-header">
            <BackLink />
            <TextSection header={data.title} paragraphs={[{ text: `${data.location.city}, ${data.location.province}` }]} />
          </div>
          <div className="job-body">
            {data.details.images.length <= 0 ? null : <ImageCarousel images={data.details.images} timer={10000} arrows={true} />}
            {data.details.paragraphs.length <= 0 ? (
              <TextSection paragraphs={[{ text: "Description Coming Soon" }]} />
            ) : (
              <TextSection paragraphs={data.details.paragraphs} />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default JobSite;
