function Hamburger({ handleHamburgerOpen, hamburgerOpen }) {
  return (
    <div className="hamburger" onClick={() => handleHamburgerOpen()}>
      {hamburgerOpen ? (
        <svg aria-hidden="true" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
      ) : (
        <svg aria-hidden="true" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
      )}
    </div>
  );
}

export default Hamburger;
