import { useEffect, useState } from "react";
import "../styling/SelectionMenu.css";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function SelectionMenu({ options, onOptionChange, defaultOption, resize = true }) {
  const smallerScreen = useMediaQuery({ maxWidth: 1200 }, undefined, handleMediaQueryChange);

  const [currentOption, setCurrentOption] = useState(options.find((option) => option.value === defaultOption)?.value ?? options[0].value);

  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    onOptionChange(currentOption);
  }, [location, onOptionChange, currentOption]);

  function changeOption(value = null) {
    setMenuOpen((prevState) => !prevState);

    if (value === currentOption || value === null) {
      return;
    }

    setCurrentOption(value);
  }

  function handleMediaQueryChange(matches) {
    if (!matches) {
      setMenuOpen(false);
    }
  }

  return (
    <>
      {resize === true ? (
        <div className={"single-selection-menu selection-menu resize"}>
          <ul>
            <li
              className={"" === currentOption ? "default selected" : "default"}
              style={{
                display: (menuOpen && smallerScreen) || "--Please Choose An Option--" === currentOption ? "block" : "none",
              }}
              onClick={() => changeOption()}>
              <span>--Please Choose An Option--</span>
            </li>
            {options.map((option, index) => (
              <li
                className={option.value === currentOption ? "selected" : ""}
                style={{ display: (menuOpen && smallerScreen) || currentOption === option.value || !smallerScreen ? "block" : "none" }}
                onClick={() => changeOption(option.value)}
                key={index}>
                <span>{option.name}</span>
              </li>
            ))}
          </ul>
          <svg
            onClick={() => changeOption(currentOption)}
            aria-hidden="true"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
            viewBox="0 0 24 24"
            style={{ transform: menuOpen && smallerScreen ? "rotate(180deg)" : "" }}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      ) : (
        <div className={"single-selection-menu selection-menu no-resize"}>
          <ul>
            <li
              className={"" === currentOption ? "default selected" : "default"}
              style={{ display: menuOpen || "--Please Choose An Option--" === currentOption ? "block" : "none" }}
              onClick={() => changeOption()}>
              <span>--Please Choose An Option--</span>
            </li>
            {options.map((option, index) => (
              <li
                className={option.value === currentOption ? "selected" : ""}
                style={{ display: menuOpen || option.value === currentOption ? "block" : "none" }}
                onClick={() => changeOption(option.value)}
                key={index}>
                <span>{option.name}</span>
              </li>
            ))}
          </ul>
          <svg
            onClick={() => changeOption(currentOption)}
            aria-hidden="true"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
            viewBox="0 0 24 24"
            style={{ transform: menuOpen ? "rotate(180deg)" : "" }}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      )}
    </>
  );
}

export default SelectionMenu;
