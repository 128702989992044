import "../styling/PageTitle.css";

function PageTitle({ title, image }) {
  return (
    <div className="page-title">
      <h1>{title}</h1>
      <img src={image} alt="" loading="lazy" />
    </div>
  );
}

export default PageTitle;
