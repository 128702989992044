import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./components/HomePage";
import OurExpertisePage from "./components/OurExpertisePage";

import Expertise from "./components/Expertise";
import OurFleetPage from "./components/OurFleetPage";
import Equipment from "./components/Equipment";
import OurCrewPage from "./components/OurCrewPage";
import DetailedCrew from "./components/DetailedCrew";
import OurWorkPage from "./components/OurWorkPage";
import JobSite from "./components/JobSite";
import ContactUsPage from "./components/ContactUsPage";

const pages = [
  { name: "Home", link: "/" },
  { name: "Our Expertise", link: "/our-expertise" },
  { name: "Our Work", link: "/our-work" },
  { name: "Our Fleet", link: "/our-fleet" },
  { name: "Our Crew", link: "/our-crew" },
  { name: "Contact Us", link: "/contact-us" },
];

function App() {
  return (
    <div className="App">
      <Header pages={pages} />
      <div className="container">
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/our-crew" element={<OurCrewPage />}>
            <Route path=":crewMember" element={<DetailedCrew />} />
          </Route>
          <Route path="/our-expertise" element={<OurExpertisePage />}>
            <Route path=":category" element={<Expertise />} />
          </Route>
          <Route path="/our-fleet" element={<OurFleetPage />}>
            <Route path=":category" element={<Equipment />} />
          </Route>
          <Route path="/our-work" element={<OurWorkPage />}>
            <Route path=":jobSite" element={<JobSite />} />
          </Route>
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="*" element={<Navigate to='/' replace />}/>
        </Routes>
        <Footer pages={pages} />
      </div>
    </div>
  );
}

export default App;
