import "../styling/HomePage.css";
import BackgroundImage from "./BackgroundImage";
import Button from "./Button";
import GriddedLinks from "./GriddedLinks";
import ImageCarousel from "./ImageCarousel";
import TextSection from "./TextSection";

const images = [
  { link: "images/home-page/image-carousel/170-ton-terex-demag-ac140.webp" },

  { link: "images/home-page/image-carousel/liebherr-300-ton-design.webp" },
  { link: "images/home-page/image-carousel/paterson-300-ton-liebherr.webp" },
  { link: "images/home-page/image-carousel/portage-la-prarie-manitowoc.webp" },
  { link: "images/home-page/image-carousel/170-ton-riel-converter-station.webp" },
  { link: "images/home-page/image-carousel/haywood-plant-expansion.webp" },
  { link: "images/home-page/image-carousel/baconies-les-suite-marion.webp" },
  { link: "images/home-page/image-carousel/90-ton-great-canadian-oil.webp" },
];

function HomePage() {
  return (
    <main className="home-page">
      <section className="home-page-carousel">
        <div className="floating-box">
          <h1>Competent. Industrious. Loyal. Safe.</h1>
          <Button text="Contact Us" link="/contact-us" />
        </div>
        <ImageCarousel images={images} timer="10000" lowerArrows="true" />
      </section>
      <section className="about-us">
        <TextSection
          header="Who We Are"
          paragraphs={[
            {
              text: "We build. We do important work. We have and will accomplish great things because we hold ourselves to high internal standards of competency and excellence. We display loyalty to each other, our clients and ourselves through our industrious natures.",
            },
            {
              test: "Competent, industrious, and loyal people create prosperous environments.",
            },
          ]}
        />
      </section>

      <section className="what-we-do">
        <TextSection header="What We Do" />
        <GriddedLinks
          gridItems={[
            {
              title: "Crane Rental",
              link: "/our-expertise/crane-rental",
              image: "images/home-page/expertise-quick-links/crane-rental.webp",
            },
            {
              title: "Bridge Construction",
              link: "/our-expertise/bridge-construction",
              image: "images/home-page/expertise-quick-links/bridge-construction.webp",
            },
            {
              title: "Heavy Hauling",
              link: "/our-expertise/heavy-hauling",
              image: "images/home-page/expertise-quick-links/heavy-hauling.webp",
            },
            {
              title: "Precast Concrete Erection",
              link: "/our-expertise/precast-concrete-erection",
              image: "images/home-page/expertise-quick-links/precast-concrete-erecting.webp",
            },
            {
              title: "Engineered / Specialty Hoisting",
              link: "/our-expertise/engineered-specialty-hoisting",
              image: "images/home-page/expertise-quick-links/engineered-specialty-hoisting.webp",
            },
          ]}
        />
        <BackgroundImage link="/images/logos/silver-phoenix-background.png" />
      </section>
    </main>
  );
}

export default HomePage;
