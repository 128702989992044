import Nav from "./Nav";
import "../styling/Header.css";
import { Link } from "react-router-dom";

function Header({ pages }) {
  return (
    <header>
      <div className="main-header">
        <div className="logo">
          <Link to="/">
            <img src={"/images/logos/silver-phoenix-complete-2.png"} alt="" loading="lazy" />
          </Link>
        </div>
        <Nav pages={pages} />
      </div>
    </header>
  );
}

export default Header;
